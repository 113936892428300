//Componente Header del sitio, encargado de contener el NavBar y submenus de navegacion
import React from "react";
import { NavDropdown, Nav, Navbar, Container, Row, Col } from "react-bootstrap";//importacion biblioteca react-bootstrap
import { NavLink } from "react-router-dom";//importacion de navLink para rutas
import Top from "../Shared/Top";//importacion componente Top
import Spain from "../../resources/Flag_of_Spain.svg"
import Usa from "../../resources/Flag_of_the_United_States.svg"
import { useTranslation } from "react-i18next";
import CalendarioAcademico from "../../pdf/CalendarioAcademico/Calendario2024UAIFinal.pdf";


const Header = () => {
  const [t, i18n]= useTranslation("global");
    return(
        <>  
          <Top/> 
            
            <Navbar expand="xl">
              
              <Container className="w-100 justify-content-center " fluid  >
                
                <Row className="w-100 justify-content-center ">
                  
                  {/*}Navbar para pantallas hasta tamaño xl{*/} 
                  <Col className="d-none d-xl-block justify-content-center">

                    {/*}propiedades navbar{*/}
                    <Nav className="navbar navbar-light nav-fill bg-light shadow-sm w-100 justify-content-center" style={{ zIndex: '1' }}>
        
                      <NavLink to="/" style={{ textDecoration: 'none' }}>
                        <Nav.Link href="#home" className="fw-bolder text-black">{t("header.t1")}</Nav.Link>{/*}ruta "/" para "inicio"{*/}
                      </NavLink>

                      <NavDropdown
                        className="fw-bolder"
                        id="nav-dropdown-6"
                        title={t("header.t3")}
                        menuVariant="light"
                      >{/*}Propiedades de submenu{*/}

                        <NavLink to="/Malla2023Pr" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.1">{t("header.t48")}</NavDropdown.Item>{/*}ruta "/ProcedimientosPracticas" para "Procedimientos"{*/}
                        </NavLink>


                        <NavLink to="/Malla2024Q" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.2">{t("header.t49")}</NavDropdown.Item>{/*}ruta "/Mallas Segundo ciclo"{*/}
                        </NavLink>


                      </NavDropdown>

                      <NavDropdown
                        className="fw-bolder"
                        id="nav-dropdown-6"
                        title={t("header.t23")}
                        menuVariant="light"
                      >
                        {/*}Propiedades de Reglamentos{*/}

                        <NavLink to="/ReglamentoGeneral" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.1">{t("header.t2")}</NavDropdown.Item>
                          </NavLink>
                          {/*}ruta "/ProcedimientosPracticas" para "Reglamento"{*/}

                        <NavLink to="/ReglamentoPracticas" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.2">{t("header.t51")}</NavDropdown.Item>
                        </NavLink>
                        {/*}ruta "/ProcedimientosPracticas" para "Reglamento Practica"{*/}
                          
                        <NavLink to="/Normativas" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.3">{t("header.t12")}</NavDropdown.Item>
                        </NavLink>
                        {/*}ruta "/ProcedimientosPracticas" para "Normativas"{*/}



                      </NavDropdown>

                      <NavDropdown
                        className="fw-bolder"
                        id="nav-dropdown-6"
                        title= {t("header.calendars")}
                        menuVariant="light"
                      >
                        {/*}Propiedades de Reglamentos{*/}

                        <NavLink to="/Calendario" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#calendario">{t("header.t5")}</NavDropdown.Item>
                          </NavLink>
                          {/*}ruta "/" para "Calendario Pruebas Y Examenes"{*/}

        
                          <NavDropdown.Item href= {CalendarioAcademico} target="_blank" rel="noreferrer"  style={{height: "100%", width: "100%", textDecoration:"none", display: "block", position: "relative"}} >
                            {t("header.t54")}
                          </NavDropdown.Item>
                        {/*}ruta  para "Calendario Academico"{*/}
                          
                      </NavDropdown>

                      {/*}submenu "Primer Ciclo"{*/}
                      <NavDropdown
                        className="fw-bolder"
                        id="nav-dropdown-4"
                        title={t("header.t40")}
                        menuVariant="light"
                      >

                        {/*}MallasPr{*/}
                        <NavLink to="/Malla2023Pr" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.1">{t("header.t48")}</NavDropdown.Item>
                        </NavLink>

                      { /*}Ocultamos Calendario y examenes de Primer Ciclo{*/}
                      
                        {/*}
                        <NavLink to="/Calendario" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.3">{t("header.t5")}</NavDropdown.Item>
                        </NavLink>
                      {*/}


                        <NavLink to="/CoordinacionPregrado" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#/pregrado">{t("header.t7")}</NavDropdown.Item>{/*}ruta "/CoordinacionPregrado" para "Coordinación Pregrado"{*/}
                        </NavLink>
                        <NavLink to="/CharlasPregrado" style={{ textDecoration: 'none' }}>

                          
                        {/* OCULTAMOS CHARLAS 
                        <NavDropdown.Item href="#action/3.8">{t("header.t8")}</NavDropdown.Item>
                        
                        */}
                          {/*}ruta "/CharlasPregrado" para "Charlas Sumate al Zoom"{*/}


                        </NavLink>
                        <NavLink to="/PreguntasPregado" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.8">{t("header.t9")}</NavDropdown.Item>{/*}ruta "/PreguntasPregado" para "Preguntas frecuentes"{*/}
                        </NavLink>
                        <NavLink to="/Minors" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.9">{t("header.t52")}</NavDropdown.Item>{/*}ruta "/Minors"{*/}
                        </NavLink>
                      </NavDropdown>

                      {/*}accionador submenu "Segundo Ciclo"{*/}
                      <NavDropdown
                        className="fw-bolder"
                        id="nav-dropdown-5"
                        title={t("header.t41")}
                        menuVariant="light"
                      >{/*}Propiedades de submenu{*/}





                          {/*OCULTAMOS FINANZAS
                        <NavDropdown.Item href="/">{t("header.t13")}</NavDropdown.Item>
                          
                          {*/}

                        <NavLink to="/Malla2024Q" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.2">{t("header.t49")}</NavDropdown.Item>
                        </NavLink>


                        
                          {/*OCULTAMOS Calendario Academico
                        <NavDropdown.Item href="/">{t("header.t13")}</NavDropdown.Item>
                          
                          
                        <NavDropdown.Item href="https://1drv.ms/x/s!AtJKhN1vnh7SgoBxyahW1JtK2Yyz4Q?e=UO5foF">{t("header.t14")}</NavDropdown.Item>Academico"
                        {*/}
                        
                        {/* Oculare mientrás esta, no tiene sentido tenerla
                        <NavDropdown.Item href="/">{t("header.t16")}</NavDropdown.Item>
                        */}


                        {/*} Comentamos el optativo profesional
                        <NavDropdown.Item href="/">{t("header.t17")}</NavDropdown.Item>
                      {*/}  

                        
                        <NavLink to="/Condiciones" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="/Condiciones">{t("header.t18")}</NavDropdown.Item>
                        </NavLink>{/*}ruta " para "Condiciones Egreso/titulacion"{*/}
                        <NavLink to="/CoordinacionSC" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="/CoordinacionSC">{t("header.t19")}</NavDropdown.Item>
                        </NavLink>{/*}ruta " para "Coordinacion Segundo Ciclo"{*/}
                        

                        {/*} no hay 20 porque se elimino uno {*/}  
                        {/*} Se oculta Preguntas Frecuentes  
                        <NavDropdown.Item href="/">{t("header.t21")}</NavDropdown.Item>
                        */}


                        <NavLink to="/Mencion" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="/Mencion">{t("header.t53")}</NavDropdown.Item>
                        </NavLink>{/*}ruta " para "Coordinacion Segundo Ciclo"{*/}
                      </NavDropdown>
                      
                      {/*}accionador submenu "Minors/Mención"{*/}  
                      <NavDropdown
                        className="fw-bolder"
                        id="nav-dropdown-5"
                        title={t("header.t55")}
                        menuVariant="light"
                      >{/*}Propiedades de submenu{*/}
                      <NavLink to="/Minors" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.9">{t("header.t52")}</NavDropdown.Item>{/*}ruta "/Minors"{*/}
                        </NavLink>
                        <NavLink to="/Mencion" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="/Mencion">{t("header.t53")}</NavDropdown.Item>
                        </NavLink>{/*}ruta " para "Mencion"{*/}
                      </NavDropdown>


                      {/*}accionador submenu "practicas"{*/}  
                      <NavDropdown
                        className="fw-bolder"
                        id="nav-dropdown-6"
                        title={t("header.t42")}
                        menuVariant="light"
                      >{/*}Propiedades de submenu{*/}
                        <NavLink to="/ProcedimientosPracticas" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.1">{t("header.t22")}</NavDropdown.Item>{/*}ruta "/ProcedimientosPracticas" para "Procedimientos"{*/}
                        </NavLink>
                        <NavLink to="/ReglamentoPracticas" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.2">{t("header.t23")}</NavDropdown.Item>
                        </NavLink>{/*}ruta "" para "Reglamento"{*/}
                        <NavLink to="/PreguntasPracticas" style={{ textDecoration: 'none' }}>
                          <NavDropdown.Item href="#action/3.3">{t("header.t24")}</NavDropdown.Item>{/*}ruta para "Preguntas Frecuentes"{*/}
                          </NavLink>
                      </NavDropdown>

                      {/*}accionador submenu "TID"{*/}
                      <NavLink to="/TallerPregrado" style={{ textDecoration: 'none' }}>
                        <Nav.Link href="#TID" className="fw-bolder">{t("header.t6")}</Nav.Link>{/*}ruta para "TID"{*/}
                      </NavLink>

                      {/*}accionador submenu "Mecanismos de titulacion"{*/}
                      <NavDropdown
                          className="fw-bolder"
                          id="nav-dropdown-7"
                          title={t("header.t43")}
                          menuVariant="light"
                        >{/*}Propiedades de submenu{*/}
                          <NavLink to="/InformacionTitulacion" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.1">{t("header.t25")}</NavDropdown.Item>
                          </NavLink>
                          <NavLink to="/EmprendimientoTitulacio" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.2">{t("header.t26")}</NavDropdown.Item>
                          </NavLink>
                          <NavLink to="/PasantiaTitulacion" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.3">{t("header.t27")}</NavDropdown.Item>
                          </NavLink>
                          <NavLink to="/MagisterTitulacion" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.4">{t("header.t28")}</NavDropdown.Item>
                          </NavLink>
                          <NavLink to="/MagisterTitulacionInternacional" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.5">{t("header.t29")}</NavDropdown.Item>
                          </NavLink>

                          <NavLink to="/PreguntasTitulacion" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.6">{t("header.t47")}</NavDropdown.Item>
                          </NavLink>


                          
                          


                      </NavDropdown>

                      {/*}accionador submenu "internacionalizacion"{*/}
                      <NavDropdown
                          className="fw-bolder"
                          id="nav-dropdown-9"
                          title={t("header.t44")}
                          menuVariant="light"
                        >{/*}Propiedades de submenu{*/}
                          <NavLink to="/CoordinacionInternacionalizacion" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.1">{t("header.t30")}</NavDropdown.Item>
                          </NavLink>
                          <NavDropdown.Item href="http://ingenieria.uai.cl/internacionalizacion/">{t("header.t31")}</NavDropdown.Item>
                          <NavLink to="/PreguntasInternacionalizacion" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.2">{t("header.t32")}</NavDropdown.Item>
                          </NavLink>
                      </NavDropdown>
                        
                      {/*}accionador submenu Acreditaciones{*/}
                      <NavDropdown
                          className="fw-bolder"
                          id="nav-dropdown-10"
                          title={t("header.t45")}
                          menuVariant="light"
                        >{/*}Propiedades de submenu{*/}
                          <NavLink to="/ComisionAcreditaciones" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.1">{t("header.t33")}</NavDropdown.Item>
                          </NavLink>
                          <NavLink to="/AcreditacionAcreditaciones" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.2">{t("header.t34")}</NavDropdown.Item>
                          </NavLink>
                          <NavLink to="/ColegioAcreditaciones" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.3">{t("header.t35")}</NavDropdown.Item>
                          </NavLink>
                      </NavDropdown>

                      {/*}accionador submenu Asuntos Estudiantiles{*/}
                      <NavDropdown
                          className="fw-bolder"
                          id="nav-dropdown-11"
                          title={t("header.t46")}
                          menuVariant="light"
                        >{/*}Propiedades de submenu{*/}
                          <NavDropdown.Item href="https://alumno.uai.cl/santiago/asuntos-estudiantiles/dae/vida-universitaria/">{t("header.t36")}</NavDropdown.Item>
                          <NavDropdown.Item href="https://alumno.uai.cl/vina-del-mar/dae/vida-universitaria/">{t("header.t37")}</NavDropdown.Item>
                          <NavDropdown.Item href=" https://admision.uai.cl/admision/becas-universidad-adolfo-ibanez/">{t("header.t38")}</NavDropdown.Item>
                          <NavLink to="/PreguntasAsuntos" style={{ textDecoration: 'none' }}>
                            <NavDropdown.Item href="#action/3.4">{t("header.t39")}</NavDropdown.Item>
                          </NavLink>
                      </NavDropdown>

                    </Nav>

                    <div className="p-3"/>

                  </Col>


                  {/*}Navbar para pantallas desde tamaño lg hasta sm{*/}
                  <Col className="d-xl-none">
                  <Navbar collapseOnSelect fixed="top" expand="xxl" bg="dark" variant="dark">
                        <Container>
                          <Navbar.Brand href="/">
                            <img 
                              src="https://www.uai.cl/assets/themes/uai-central/img/logo.svg"
                              width="120"
                              height="50"
                            />
                          </Navbar.Brand>{/*}Logo UAI{*/}
                          
                          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                          
                          <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav>


                            <NavLink to="/" style={{ textDecoration: 'none' }}>
                      <Nav.Link href="#home">{t("header.t1")}</Nav.Link>{/*}ruta "/" para "inicio"{*/}
                      </NavLink>

                            <NavDropdown
                            
                            id="nav-dropdown-6"
                            title={t("header.t3")}
                            menuVariant="light"
                          >{/*}Propiedades Mallas Curriculares{*/}

                            <NavLink to="/Malla2023Pr" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.1">{t("header.t48")}</NavDropdown.Item>{/*}ruta "/Mallas2023 Primer Ciclo"{*/}
                            </NavLink>
                            <NavLink to="/Malla2024Q" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.2">{t("header.t49")}</NavDropdown.Item>{/*}ruta "/Mallas Segundo ciclo"{*/}
                            </NavLink>

                          </NavDropdown>


                          <NavDropdown
                            id="nav-dropdown-6"
                            title={t("header.t23")}
                            menuVariant="light"
                          >{/*}Propiedades de Reglamentos{*/}

                            <NavLink to="/ReglamentoGeneral" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.1">{t("header.t2")}</NavDropdown.Item>{/*}ruta "/ProcedimientosPracticas" para "Reglamento"{*/}
                            </NavLink>
                            <NavLink to="/ReglamentoPracticas" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.2">{t("header.t51")}</NavDropdown.Item>{/*}ruta "/ProcedimientosPracticas" para "Reglamento Practica"{*/}
                            </NavLink>
                            <NavLink to="/Normativas" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.3">{t("header.t12")}</NavDropdown.Item>{/*}ruta "/ProcedimientosPracticas" para "Normativas"{*/}
                            </NavLink>

                          </NavDropdown>






                      <NavDropdown
                            id="nav-dropdown-6"
                            title={t("header.calendars")}
                            menuVariant="light"
                          >{/*}Propiedades de Reglamentos{*/}


                            <NavLink to="/Calendario" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#calendario">{t("header.t5")}</NavDropdown.Item>{/*}ruta "/" para "Calendario Pruebas Y Examenes"{*/}
                            </NavLink>

                              <NavDropdown.Item href= {CalendarioAcademico} target="_blank" rel="noreferrer"  style={{height: "100%", width: "100%", textDecoration:"none", display: "block", position: "relative"}} >
                                {t("header.t54")}{/*}ruta "/" para "Calendario Academico"{*/}
                              </NavDropdown.Item>


                          </NavDropdown>

                            
                            
                      
                      
                      <NavDropdown
                            id="nav-dropdown-4"
                            title={t("header.t40")}
                            menuVariant="light"
                          >{/*}Propiedades de Primer Ciclo{*/}

                            {/*}MallasPr{*/}
                            <NavLink to="/Malla2023Pr" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.1">{t("header.t48")}</NavDropdown.Item>
                            </NavLink>{/*}ruta para "Mallas Primer Ciclo"{*/}

  
                            { /*}Ocultamos Calendario y examenes de Primer Ciclo{*/}
                            {/*}
                            <NavLink to="/Calendario" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.3">{t("header.t5")}</NavDropdown.Item>
                            </NavLink>
                          {*/}


                            <NavLink to="/CoordinacionPregrado" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#/pregrado">{t("header.t7")}</NavDropdown.Item>{/*}ruta "/CoordinacionPregrado" para "Coordinación Pregrado"{*/}
                            </NavLink>

                            {/* OCULTAMOS CHARLAS
                            <NavLink to="/CharlasPregrado" style={{ textDecoration: 'none' }}>
                            
                            <NavDropdown.Item href="#action/3.7">{t("header.t8")}</NavDropdown.Item> 
                            </NavLink>
                            */}

                            <NavLink to="/PreguntasPregado" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.8">{t("header.t9")}</NavDropdown.Item>{/*}ruta "/PreguntasPregado" para "Preguntas frecuentes"{*/}
                            </NavLink>

                            <NavLink to="/Minors" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.9">{t("header.t52")}</NavDropdown.Item>{/*}ruta "/Minors" para "Minors"{*/}
                            </NavLink>
                          </NavDropdown>






                          {/*}accionador submenu "Segundo Ciclo"{*/}
                          <NavDropdown
                            id="nav-dropdown-5"
                            title={t("header.t41")}
                            menuVariant="light"
                          >{/*}Propiedades de Segundo Ciclo{*/}






                              {/*OCULTAMOS FINANZAS
                            <NavDropdown.Item href="/">{t("header.t13")}</NavDropdown.Item>
                              
                              {*/}

                            <NavLink to="/Malla2024Q" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.2">{t("header.t49")}</NavDropdown.Item>
                            </NavLink>{/*}ruta " para "Mallas Segundo Ciclo"{*/}
                              {/*}Ocultar Calendario Academico
                            <NavDropdown.Item href="https://1drv.ms/x/s!AtJKhN1vnh7SgoBxyahW1JtK2Yyz4Q?e=UO5foF">{t("header.t14")}</NavDropdown.Item>"{*/}


                            {/* Oculare mientrás esta, no tiene sentido tenerla
                            <NavDropdown.Item href="/">{t("header.t16")}</NavDropdown.Item>
                            */}
                        

                            <NavLink to="/Condiciones" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="/Condiciones">{t("header.t18")}</NavDropdown.Item>
                            </NavLink>
                            {/*}ruta " para "Condiciones Egreso/titulacion"{*/}

                            <NavLink to="/CoordinacionSC" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="/CoordinacionSC">{t("header.t19")}</NavDropdown.Item>
                            </NavLink>
                            {/*}ruta " para "Coordinacion Segundo Ciclo"{*/}

                            <NavLink to="/Mencion" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="/Mencion">{t("header.t53")}</NavDropdown.Item>
                            </NavLink>
                            {/*}ruta " para "Mencion"{*/}


                          </NavDropdown>




                          {/*}accionador submenu "Minors/Mención"{*/}
                          <NavDropdown
                            id="nav-dropdown-5"
                            title={t("header.t55")}
                            menuVariant="light"
                          >{/*}Propiedades de Segundo Ciclo{*/}

                            <NavLink to="/Minors" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="/Minors">{t("header.t52")}</NavDropdown.Item>{/*}ruta "/Minors" para "Minors"{*/}
                            </NavLink>






                            <NavLink to="/Mencion" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="/Mencion">{t("header.t53")}</NavDropdown.Item>
                            </NavLink>
                            {/*}ruta " para "Mencion"{*/}


                          </NavDropdown>






                          {/*}accionador submenu "practicas"{*/}
                          <NavDropdown
                            id="nav-dropdown-6"
                            title={t("header.t42")}
                            menuVariant="light"
                          >{/*}Propiedades de submenu{*/}
                            <NavLink to="/ProcedimientosPracticas" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.1">{t("header.t22")}</NavDropdown.Item>{/*}ruta "/ProcedimientosPracticas" para "Procedimientos"{*/}
                            </NavLink>
                            <NavLink to="/ReglamentoPracticas" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.2">{t("header.t23")}</NavDropdown.Item>
                            </NavLink>{/*}ruta "" para "Reglamento"{*/}
                            <NavLink to="/PreguntasPracticas" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.3">{t("header.t24")}</NavDropdown.Item>
                              </NavLink>{/*}ruta para "Preguntas Frecuentes"{*/}
                          </NavDropdown>

                          
                          <NavLink to="/TallerPregrado" style={{ textDecoration: 'none' }}>
                            <Nav.Link href="#TID" >{t("header.t6")}</Nav.Link>{/*}ruta "/" para "TID"{*/}
                          </NavLink>



                          <NavDropdown
                            id="nav-dropdown-7"
                            title={t("header.t43")}
                            menuVariant="light"
                          >{/*}Propiedades de Mecanismos de Titulacion{*/}
                            <NavLink to="/InformacionTitulacion" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.1">{t("header.t25")}</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/EmprendimientoTitulacio" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.2">{t("header.t26")}</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/PasantiaTitulacion" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.3">{t("header.t27")}</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/MagisterTitulacion" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.4">{t("header.t28")}</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/MagisterTitulacionInternacional" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.5">{t("header.t29")}</NavDropdown.Item>
                            </NavLink>

                            <NavLink to="/PreguntasTitulacion" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.6">{t("header.t47")}</NavDropdown.Item>
                            </NavLink>


                            
                            
                          </NavDropdown>

                          {/*}accionador submenu "internacionalizacion"{*/}
                          <NavDropdown
                            id="nav-dropdown-9"
                            title={t("header.t44")}
                            menuVariant="light"
                          >
                            <NavLink to="/CoordinacionInternacionalizacion" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.1">{t("header.t30")}</NavDropdown.Item>
                            </NavLink>
                            <NavDropdown.Item href="http://ingenieria.uai.cl/internacionalizacion/">{t("header.t31")}</NavDropdown.Item>
                            <NavLink to="/PreguntasInternacionalizacion" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.2">{t("header.t32")}</NavDropdown.Item>
                            </NavLink>
                          </NavDropdown>

                          {/*}accionador submenu Acreditaciones{*/}
                          <NavDropdown
                            id="nav-dropdown-10"
                            title={t("header.t45")}
                            menuVariant="light"
                          >{/*}Propiedades de submenu{*/}
                            <NavLink to="/ComisionAcreditaciones" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.1">{t("header.t33")}</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/AcreditacionAcreditaciones" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.2">{t("header.t34")}</NavDropdown.Item>
                            </NavLink>
                            <NavLink to="/ColegioAcreditaciones" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.3">{t("header.t35")}</NavDropdown.Item>
                            </NavLink>
                          </NavDropdown>

                          {/*}accionador submenu Asuntos Estudiantiles{*/}
                          <NavDropdown
                            id="nav-dropdown-11"
                            title={t("header.t46")}
                            menuVariant="light"
                          >
                            <NavDropdown.Item href="https://alumno.uai.cl/santiago/asuntos-estudiantiles/dae/vida-universitaria/">{t("header.t36")}</NavDropdown.Item>
                            <NavDropdown.Item href="https://alumno.uai.cl/vina-del-mar/dae/vida-universitaria/">{t("header.t37")}</NavDropdown.Item>
                            <NavDropdown.Item href=" https://admision.uai.cl/admision/becas-universidad-adolfo-ibanez/">{t("header.t38")}</NavDropdown.Item>
                            <NavLink to="/PreguntasAsuntos" style={{ textDecoration: 'none' }}>
                              <NavDropdown.Item href="#action/3.4">{t("header.t39")}</NavDropdown.Item>
                            </NavLink>
                          </NavDropdown>

                          <NavDropdown
                            id="nav-dropdown-12"
                            title={t("top.idioma")}
                            menuVariant="light"
                          >
                              <NavDropdown.Item>
                                  <button
                                  onClick={() => i18n.changeLanguage("es")}
                                  className="custom-button-idioma"
                                  >
                                  <div className="d-flex align-items-center">
                                      <img
                                      src={Spain}
                                      alt="spain"
                                      style={{ width: '20px', marginRight: '5px' }}
                                      />
                                      ES
                                  </div>
                                  </button>
                              </NavDropdown.Item>
                              <NavDropdown.Item>
                                  <button
                                  onClick={() => i18n.changeLanguage("en")}
                                  className="custom-button-idioma"
                                  >
                                  <div className="d-flex align-items-center">
                                      <img
                                      src={Usa}
                                      alt="usa"
                                      style={{ width: '20px', marginRight: '5px' }}
                                      />
                                      EN
                                  </div>
                                  </button>
                              </NavDropdown.Item>
                          </NavDropdown>
                            </Nav>
                          </Navbar.Collapse>
                        </Container>
                      </Navbar>
                      <div className="p-4"/>
                  </Col>
                </Row>
              </Container>
              
            </Navbar>
            
           </>

    );
}

export default Header
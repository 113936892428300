//componente Home del sitio, encargado de contener todos los elementos correspondientes a la pagina de inicio
import React from "react";
import { Container, Row, Col, Button, Card, Image } from 'react-bootstrap';
import '../../styles/PruebaDashboard.css'
import pregrado_logo from "../Home/santiago.jpg";
import uai from "../../resources/uai.jpg"
import uai1_1 from "../../resources/uai1_1.jpeg"
import uai2 from "../../resources/uai2.jpg"
import alumnos from "../../resources/alumnos.jpg"
import about from "../../resources/about.jpeg"
import about1 from "../../resources/about1.jpeg"
import coordinacion from "../../resources/coordinacion_1.jpeg"
import work from "../../resources/work1.jpeg"
import uai_2 from "../../resources/uai_2.jpeg"
import taller from "../../resources/taller.jpg"
import titulacion from "../../resources/titulacion.jpg"
import inter from "../../resources/inter.jpeg"
import acreditacion from "../../resources/acreditacion1.jpeg"
import bachi from "../../resources/bachillerato1.jpg"
import bachi2 from "../../resources/bachillerato2.jpeg"
import question from "../../resources/question.jpg"
import work2 from "../../resources/work2.jpg"
import rules from "../../resources/rules.png"
import pasantia from "../../resources/pasantia1.jpg"
import clases from "../../resources/class.jpg"
import inter2 from "../../resources/inter2.jpeg"
import acreditacion1 from "../../resources/acreditacion1.jpg"
import internacional1 from "../../resources/internacional1.jpg"
import ing1 from "../../resources/ing1.jpg"

import { useTranslation } from "react-i18next";


const PruebaDashboard = () =>{
    const [t, i18n]= useTranslation("global");
    return(        
    <>
        

        <Container fluid className="py-5">
                            <Row className="justify-content-center mb-5">
                    <Col xs={12} className="p-0 position-relative ">
                        <Image src={uai} fluid className="w-100 blurred-image sombra-imagen" />
                        <div className="position-absolute top-0 start-50 translate-middle-x text-center text-white overlay py-3">
                        </div>
                    </Col>
                </Row>

        <Row className="justify-content-center mb-5">
                <Col xs={6} className="p-0">
                    <div className="text-center mb-5 w-100">
                        <h1 className="pb-5">Bienvenido a Alumnos FIC</h1>
                        <p className="text-center">
                        Aquí podrás explorar e informarte sobre todo lo necesario para el transcurso de tu carrera en la universidad. 
                        Encontrarás recursos académicos, calendarios, noticias, eventos, procedimientos administrativos, 
                        y mucho más para ayudarte a tener una experiencia universitaria exitosa y enriquecedora.                    </p>
      
                    </div>
                </Col>
            </Row>

            {/* Encabezado del dashboard */}


            {/* Sección de tarjetas */}


            <Row className="bg-grey py-5">
                <h2 className="text-center pb-5 pt-3 mb-5">Información</h2>
                <Col md={4}>
                
                    <Card>
                        <Card.Img variant="top" src={uai_2} alt="Thumbnail" className="card-img-top"/>
                        <Card.Body>
                            <Card.Title>Mallas Curriculares</Card.Title>
                            <Card.Text>
                                Esta sección incluye las mallas curriculares correspondientes al primer y segundo ciclo de estudio. Aquí, los estudiantes encontrarán información sobre las asignaturas básicas y fundamentales que deben cursar durante años de su carrera.
                            </Card.Text>
                            <div class="list-group list-group-flush">
                                <a href="/Malla2023Pr" class="list-group-item list-group-item-action">Mallas Curriculurares Primer Ciclo</a>
                                <a href="/Malla2024Q" class="list-group-item list-group-item-action">Mallas Curriculurares Segundo Ciclo</a>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                {/* Repetimos la estructura para las siguientes tarjetas */}
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src={acreditacion1} alt="Thumbnail" />
                        <Card.Body>
                            <Card.Title>Reglamentos</Card.Title>
                            <Card.Text>
                            En esta sección, los estudiantes pueden encontrar los reglamentos y normativas que rigen la vida académica y administrativa en la Facultad de Ingeniería Civil. Es importante que los estudiantes estén familiarizados con estos documentos para asegurar el cumplimiento de las normas y procedimientos establecidos.
                            </Card.Text>
                            <Button variant="primary" className="me-2">View</Button>
                            <Button variant="secondary">Edit</Button>
                        </Card.Body>

                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src={uai} alt="Thumbnail" />
                        <Card.Body>
                            <Card.Title>Calendarios</Card.Title>
                            <Card.Text>
                                Mantente al día con las fechas propuestas por la universidad.

                            </Card.Text>
                            <Button variant="primary" className="me-2">View</Button>
                            <Button variant="secondary">Edit</Button>
                        </Card.Body>

                    </Card>
                </Col>
            </Row>
            
            <Row className="bg-grey">
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src={uai_2} alt="Thumbnail" />
                        <Card.Body>
                            <Card.Title>Primer Ciclo</Card.Title>
                            <Card.Text>
                                Esta sección incluye las mallas curriculares correspondientes al primer y segundo ciclo de estudio. Aquí, los estudiantes encontrarán información sobre las asignaturas básicas y fundamentales que deben cursar durante años de su carrera.
                            </Card.Text>
                            <div class="list-group list-group-flush">
                                <a href="/Malla2023Pr" class="list-group-item list-group-item-action">Mallas Curriculurares Primer Ciclo</a>
                                <a href="/Malla2024Q" class="list-group-item list-group-item-action">Mallas Curriculurares Segundo Ciclo</a>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                {/* Repetimos la estructura para las siguientes tarjetas */}
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src={rules} alt="Thumbnail" />
                        <Card.Body>
                            <Card.Title>Segundo Ciclo</Card.Title>
                            <Card.Text>
                                This is a wider card with supporting text below as a natural lead-in 
                                to additional content. This content is a little bit longer.
                            </Card.Text>
                            <Button variant="primary" className="me-2">View</Button>
                            <Button variant="secondary">Edit</Button>
                        </Card.Body>

                    </Card>
                </Col>
                <Col md={4}>
                    <Card>

                        <Card.Body>
                            <Card.Title>Minors/Mención</Card.Title>
                            <Card.Text>
                                This is a wider card with supporting text below as a natural lead-in 
                                to additional content. This content is a little bit longer.
                            </Card.Text>
                            <Button variant="primary" className="me-2">View</Button>
                            <Button variant="secondary">Edit</Button>
                        </Card.Body>

                    </Card>
                </Col>
            </Row>

            <Row className="bg-grey">
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src={uai_2} alt="Thumbnail" />
                        <Card.Body>
                            <Card.Title>Prácticas</Card.Title>
                            <Card.Text>
                                Esta sección incluye las mallas curriculares correspondientes al primer y segundo ciclo de estudio. Aquí, los estudiantes encontrarán información sobre las asignaturas básicas y fundamentales que deben cursar durante años de su carrera.
                            </Card.Text>
                            <div class="list-group list-group-flush">
                                <a href="/Malla2023Pr" class="list-group-item list-group-item-action">Mallas Curriculurares Primer Ciclo</a>
                                <a href="/Malla2024Q" class="list-group-item list-group-item-action">Mallas Curriculurares Segundo Ciclo</a>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                {/* Repetimos la estructura para las siguientes tarjetas */}
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src="thumbnail.png" alt="Thumbnail" />
                        <Card.Body>
                            <Card.Title>Taller de investigación dirigida</Card.Title>
                            <Card.Text>
                                This is a wider card with supporting text below as a natural lead-in 
                                to additional content. This content is a little bit longer.
                            </Card.Text>
                            <Button variant="primary" className="me-2">View</Button>
                            <Button variant="secondary">Edit</Button>
                        </Card.Body>

                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src="thumbnail.png" alt="Thumbnail" />
                        <Card.Body>
                            <Card.Title>Mecanismos de TItulación</Card.Title>
                            <Card.Text>
                                This is a wider card with supporting text below as a natural lead-in 
                                to additional content. This content is a little bit longer.
                            </Card.Text>
                            <Button variant="primary" className="me-2">View</Button>
                            <Button variant="secondary">Edit</Button>
                        </Card.Body>

                    </Card>
                </Col>
            </Row>
            <Row className="bg-grey">
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src={uai_2} alt="Thumbnail" />
                        <Card.Body>
                            <Card.Title>Internacionalización</Card.Title>
                            <Card.Text>
                                Esta sección incluye las mallas curriculares correspondientes al primer y segundo ciclo de estudio. Aquí, los estudiantes encontrarán información sobre las asignaturas básicas y fundamentales que deben cursar durante años de su carrera.
                            </Card.Text>
                            <div class="list-group list-group-flush">
                                <a href="/Malla2023Pr" class="list-group-item list-group-item-action">Mallas Curriculurares Primer Ciclo</a>
                                <a href="/Malla2024Q" class="list-group-item list-group-item-action">Mallas Curriculurares Segundo Ciclo</a>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                {/* Repetimos la estructura para las siguientes tarjetas */}
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src="thumbnail.png" alt="Thumbnail" />
                        <Card.Body>
                            <Card.Title>Acreditaciones</Card.Title>
                            <Card.Text>
                                This is a wider card with supporting text below as a natural lead-in 
                                to additional content. This content is a little bit longer.
                            </Card.Text>
                            <Button variant="primary" className="me-2">View</Button>
                            <Button variant="secondary">Edit</Button>
                        </Card.Body>

                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img variant="top" src="thumbnail.png" alt="Thumbnail" />
                        <Card.Body>
                            <Card.Title>Asuntos Estudiantiles</Card.Title>
                            <Card.Text>
                                This is a wider card with supporting text below as a natural lead-in 
                                to additional content. This content is a little bit longer.
                            </Card.Text>
                            <Button variant="primary" className="me-2">View</Button>
                            <Button variant="secondary">Edit</Button>
                        </Card.Body>

                    </Card>
                </Col>
            </Row>
        </Container>

{/*}---------------------------------------------------------------{*/}

    <div className="d-md-none">
        <div className="p-2"/>
        {/*}PREGRADO{*/}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={uai_2}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/CoordinacionPregrado">{t("home.t1")}</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>
        {/*}QUINTO AÑO{*/}
                {/*}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={taller}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/PreguntasPregado">Quinto Año</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>
                {*/}
        {/*}PRACTICAS{*/}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={work}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/ProcedimientosPracticas">{t("home.t5")}</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>
        {/*}TITULACION{*/}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={titulacion}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/PreguntasPregado">{t("home.t9")}</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>
        {/*}INTERNACIONALIZACION{*/}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={inter}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/PreguntasPregado">{t("home.t13")}</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>
        {/*}ACREDITACIONES{*/}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={acreditacion}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/PreguntasPregado">{t("home.t16")}</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>
        {/*}ASUNTOS ESTUDIANTILES{*/}
                <div className="card shadow-lg w-auto mb-1 position-relaitve">
                    <img src={bachi}className="card-img rounded-start"/>
                    <div className="">    
                        <div className="card-img-overlay position-absolute custom-co-image">
                        <a className="card-title text-center text-light mb-5 ms-4 position-absolute start-0 bottom-0 fs-3 text-decoration-none" href="/PreguntasPregado">{t("home.t20")}</a>
                            <div className="p-1 bg-uai custom-deco mb-5 ms-2 position-absolute start-0 bottom-0"/>
                        </div>
                    </div>
                </div>


    </div>
    



</>

    )
}

export default PruebaDashboard
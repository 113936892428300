import React from "react";

import clases from "../../resources/class.jpg"
import { useTranslation } from "react-i18next";
import folleto from "../../pdf/titulacion/Folleto.pdf";
import miio from "../../pdf/titulacion/Carta_MIIO.doc";
import msds from "../../pdf/titulacion/CARTA_MSDS.doc";
import mci from "../../pdf/titulacion/CARTA_MCI.docx"

const MagisterTitulacion = () =>{
    const [t, i18n]= useTranslation("global");
    return(
    <>
    


    
    {/*}Renderizado hasta pantallas xl{*/}
    <div className="d-none d-xl-block">
        <div className="col-md-6 offset-md-3">        
                <div className="card position-relative ms-4 me-4">
                    <img src={clases}className="card-img"/>
                    <div className="card-img-overlay position-absolute custom-co-image">
                        <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("titulacion.magister_na.t1")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                    </div>
                </div>
        </div>
        <div className="p-3"/>
        
        <div className="col-md-6 offset-md-3">
                <div className="p-3"/>
                <p className="text-start fs-6">{t("titulacion.magister_na.t2")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.magister_na.t3")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.magister_na.t5")} <a className="text-decoration-none color-uai" href={folleto} target="_blank" rel="noopener noreferrer" download="Folleto_Master_Forum.pdf">{t("titulacion.magister_na.t6")}</a> {t("titulacion.magister_na.t7")}</p>
                <div className="p-3"/>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
        </div>
        <div className="bg-grey">
            <div className="col-md-6 offset-md-3">
                <div className="p-3"/>
                <h1 className="text-center fs-3">{t("titulacion.magister_na.t8")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
                <ul>
                    <li className="fs-6"><a className="text-decoration-none color-uai" href="http://ingenieria.uai.cl/postgrados/magister-en-ciencias-de-la-ingenieria/">{t("titulacion.magister_na.t9")}</a> {t("titulacion.magister_na.t10")}
                    <div className="p-2"/>
                        <ul>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector1")} (<a className="text-decoration-none color-uai" href="mailto:tledger@uai.cl">{t("titulacion.magister_na.LineDirector1_mail")}</a>)</li>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector2")} (<a className="text-decoration-none color-uai" href="mailto:dafne.crutchik@uai.cl">{t("titulacion.magister_na.LineDirector2_mail")}</a>)</li>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector3")} (<a className="text-decoration-none color-uai" href="mailto:ruth.murrugarra@uai.cl">{t("titulacion.magister_na.LineDirector3_mail")}</a>)</li>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector4")} (<a className="text-decoration-none color-uai" href="mailto:danilo.borquez@uai.cl">{t("titulacion.magister_na.LineDirector4_mail")}</a>)</li>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector5")} (<a className="text-decoration-none color-uai" href="mailto:federico.antico@uai.cl">{t("titulacion.magister_na.LineDirector5_mail")}</a>)</li>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector6")} (<a className="text-decoration-none color-uai" href="mailto:felipe.larrain@uai.cl">{t("titulacion.magister_na.LineDirector6_mail")}</a>)</li>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector7")} (<a className="text-decoration-none color-uai" href="mailto:pedro.reszka@uai.cl">{t("titulacion.magister_na.LineDirector7_mail")}</a>)</li>
                            
                        </ul>
                        <div className="p-2"/>
                    </li> 
                    <li className="fs-6"><a className="text-decoration-none color-uai" href="http://ingenieria.uai.cl/postgrados/magister-en-ingenieria-industrial-e-investigacion-de-operaciones/">{t("titulacion.magister_na.t21")}</a>{t("titulacion.magister_na.t22")}{t("titulacion.magister_na.t23")}{t("titulacion.magister_na.t24")}<a className="text-decoration-none color-uai" href="mailto:wilfredo.yushimito@uai.cl">{t("titulacion.magister_na.t25")}</a>)</li>
                    <li className="fs-6"><a className="text-decoration-none color-uai" href="https://ingenieria.uai.cl/master-of-science/master-of-science-in-data-science/">{t("titulacion.magister_na.t26")}</a>{t("titulacion.magister_na.t27")}{t("titulacion.magister_na.t28")}{t("titulacion.magister_na.t29")}<a className="text-decoration-none color-uai" href="mailto:leopoldo.bertossi@uai.cl">{t("titulacion.magister_na.t30")}</a>)</li>
                </ul>
                <div className="p-3"/>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
            </div>
        </div>
                
        <div className="col-md-6 offset-md-3">  
                        <div className="position-relative">     
                            <div className="p-3"/>
                            <h1 className="text-center fs-3">{t("titulacion.magister_na.t31")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <ul>
                                <li className="fs-6">{t("titulacion.magister_na.requisito1")}</li> 
                                <li className="fs-6">{t("titulacion.magister_na.requisito2")}</li>
                                <li className="fs-6">{t("titulacion.magister_na.requisito3")}</li>
                                <li className="fs-6">{t("titulacion.magister_na.requisito4")}</li>
                                <li className="fs-6">{t("titulacion.magister_na.requisito5")}</li>
                            </ul>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.magister_na.t36")}</p>
                            <div className="p-3"/>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        </div>
                        <div className="p-3"/>
        </div>

        <div className="bg-grey">
            <div className="col-md-6 offset-md-3">
                <div className="p-3"/>
                <h1 className="text-center fs-3">{t("titulacion.magister_na.t37")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
                <ul>
                    <li className="fs-6">{t("titulacion.magister_na.t38")}</li> 
                    <li className="fs-6">{t("titulacion.magister_na.t39")}</li>
                </ul>
                <div className="p-2"/>
                    <p className="text-start fs-6">{t("titulacion.magister_na.descripcioncarta1")}<a className="text-decoration-none color-uai" href="mailto:postgrados.fic@uai.cl">{t("titulacion.magister_na.correocarta")}</a>{t("titulacion.magister_na.descripcioncarta2")}</p>
                <ul>
                    <li className="fs-6"><a className="text-decoration-none color-uai" href={mci} target="_blank" rel="noopener noreferrer" download="Carta_MCI.docx">{t("titulacion.magister_na.infocarta1")}</a></li>
                    <li className="fs-6"><a className="text-decoration-none color-uai" href={miio} target="_blank" rel="noopener noreferrer" download="Carta_MIIO.doc">{t("titulacion.magister_na.infocarta2")}</a></li>
                    <li className="fs-6"><a className="text-decoration-none color-uai" href={msds} target="_blank" rel="noopener noreferrer" download="Carta_MSDS.doc">{t("titulacion.magister_na.infocarta3")}</a></li>
                    <li className="fs-6">{t("titulacion.magister_na.t44")}</li>
                </ul>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
            </div>
        </div>
        <div className="col-md-6 offset-md-3">  
                        <div className="position-relative">     
                            <div className="p-3"/>
  
                            <h1 className="text-center fs-3">{t("titulacion.magister_na.t45")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <p className="text-start fs-6">{t("titulacion.magister_na.infopostulacion1")}</p>
                            <p className="text-start fs-6">{t("titulacion.magister_na.infopostulacion2")}</p>
                            <p className="text-start fs-6">{t("titulacion.magister_na.infopostulacion3")} <a className="text-decoration-none color-uai postula-aqui" href="
https://forms.office.com/r/WKJh42RZqR">{t("titulacion.magister_na.infopostulacion4")}</a></p>
                            <div className="p-3"/>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        </div>
                        <div className="p-3"/>
        </div>

    </div>
    
    {/*}Renderizado desde pantallas lg{*/}
    <div className="d-xl-none">
        <div className="d-none d-md-block">
        <div className="">        
                    <div className="card position-relative">
                        <img src={clases}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("titulacion.magister_na.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>

            <div className="p-3"/>
            <div className="col-md-6 offset-md-3">
                <div className="p-3"/>
                <p className="text-start fs-6">{t("titulacion.magister_na.t2")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.magister_na.t3")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6">{t("titulacion.magister_na.t5")} <a className="text-decoration-none color-uai" href={folleto} target="_blank" rel="noopener noreferrer" download="Folleto_Master_Forum.pdf">{t("titulacion.magister_na.t6")}</a> {t("titulacion.magister_na.t7")}</p>
                <div className="p-3"/>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
        </div>
        <div className="bg-grey">
            <div className="col-md-6 offset-md-3">
                <div className="p-3"/>
                <h1 className="text-center fs-3">{t("titulacion.magister_na.t8")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
                <ul>
                    <li className="fs-6"><a className="text-decoration-none color-uai" href="http://ingenieria.uai.cl/postgrados/magister-en-ciencias-de-la-ingenieria/">{t("titulacion.magister_na.t9")}</a> {t("titulacion.magister_na.t10")}
                    <div className="p-2"/>
                        <ul>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector1")} (<a className="text-decoration-none color-uai" href="mailto:tledger@uai.cl">{t("titulacion.magister_na.LineDirector1_mail")}</a>)</li>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector2")} (<a className="text-decoration-none color-uai" href="mailto:dafne.crutchik@uai.cl">{t("titulacion.magister_na.LineDirector2_mail")}</a>)</li>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector3")} (<a className="text-decoration-none color-uai" href="mailto:ruth.murrugarra@uai.cl">{t("titulacion.magister_na.LineDirector3_mail")}</a>)</li>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector4")} (<a className="text-decoration-none color-uai" href="mailto:danilo.borquez@uai.cl">{t("titulacion.magister_na.LineDirector4_mail")}</a>)</li>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector5")} (<a className="text-decoration-none color-uai" href="mailto:federico.antico@uai.cl">{t("titulacion.magister_na.LineDirector5_mail")}</a>)</li>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector6")} (<a className="text-decoration-none color-uai" href="mailto:felipe.larrain@uai.cl">{t("titulacion.magister_na.LineDirector6_mail")}</a>)</li>
                            <li className="fs-6">{t("titulacion.magister_na.LineDirector7")} (<a className="text-decoration-none color-uai" href="mailto:pedro.reszka@uai.cl">{t("titulacion.magister_na.LineDirector7_mail")}</a>)</li>
                        </ul>
                        <div className="p-2"/>
                    </li> 
                    <li className="fs-6"><a className="text-decoration-none color-uai" href="http://ingenieria.uai.cl/postgrados/magister-en-ingenieria-industrial-e-investigacion-de-operaciones/">{t("titulacion.magister_na.t21")}</a>{t("titulacion.magister_na.t22")}{t("titulacion.magister_na.t23")}{t("titulacion.magister_na.t24")}<a className="text-decoration-none color-uai" href="mailto:wilfredo.yushimito@uai.cl">{t("titulacion.magister_na.t25")}</a>)</li>
                    <li className="fs-6"><a className="text-decoration-none color-uai" href="https://ingenieria.uai.cl/master-of-science/master-of-science-in-data-science/">{t("titulacion.magister_na.t26")}</a>{t("titulacion.magister_na.t27")}{t("titulacion.magister_na.t28")}{t("titulacion.magister_na.t29")}<a className="text-decoration-none color-uai" href="mailto:leopoldo.bertossi@uai.cl">{t("titulacion.magister_na.t30")}</a>)</li>
                </ul>
                <div className="p-3"/>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
            </div>
        </div>
                
        <div className="col-md-6 offset-md-3">  
                        <div className="position-relative">     
                            <div className="p-3"/>
                            <h1 className="text-center fs-3">{t("titulacion.magister_na.t31")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <ul>
                                <li className="fs-6">{t("titulacion.magister_na.requisito1")}</li> 
                                <li className="fs-6">{t("titulacion.magister_na.requisito2")}</li>
                                <li className="fs-6">{t("titulacion.magister_na.requisito3")}</li>
                                <li className="fs-6">{t("titulacion.magister_na.requisito4")}</li>
                                <li className="fs-6">{t("titulacion.magister_na.requisito5")}</li>
                            </ul>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.magister_na.t36")}</p>
                            <div className="p-3"/>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        </div>
                        <div className="p-3"/>
        </div>

        <div className="bg-grey">
            <div className="col-md-6 offset-md-3">
                <div className="p-3"/>
                <h1 className="text-center fs-3">{t("titulacion.magister_na.t37")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
                <ul>
                    <li className="fs-6">{t("titulacion.magister_na.t38")}</li> 
                    <li className="fs-6">{t("titulacion.magister_na.t39")}</li>
                </ul>
                <div className="p-2"/>
                    <p className="text-start fs-6">{t("titulacion.magister_na.descripcioncarta1")}<a className="text-decoration-none color-uai" href="mailto:postgrados.fic@uai.cl">{t("titulacion.magister_na.correocarta")}</a>{t("titulacion.magister_na.descripcioncarta2")}</p>
                <ul>
                    <li className="fs-6"><a className="text-decoration-none color-uai" href={mci} target="_blank" rel="noopener noreferrer" download="Carta_MCI.docx">{t("titulacion.magister_na.infocarta1")}</a></li>
                    <li className="fs-6"><a className="text-decoration-none color-uai" href={miio} target="_blank" rel="noopener noreferrer" download="Carta_MIIO.doc">{t("titulacion.magister_na.infocarta2")}</a></li>
                    <li className="fs-6"><a className="text-decoration-none color-uai" href={msds} target="_blank" rel="noopener noreferrer" download="Carta_MSDS.doc">{t("titulacion.magister_na.infocarta3")}</a></li>
                    <li className="fs-6">{t("titulacion.magister_na.t44")}</li>
                </ul>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
            </div>
        </div>
        <div className="col-md-6 offset-md-3">  
                        <div className="position-relative">     
                            <div className="p-3"/>

                            <h1 className="text-center fs-3">{t("titulacion.magister_na.t45")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <p className="text-start fs-6">{t("titulacion.magister_na.infopostulacion1")}</p>
                            <p className="text-start fs-6">{t("titulacion.magister_na.infopostulacion2")}</p>
                            <p className="text-start fs-6">{t("titulacion.magister_na.infopostulacion3")} <a className="postula-aqui" href="
https://forms.office.com/r/WKJh42RZqR">{t("titulacion.magister_na.infopostulacion4")}</a></p>
                            <div className="p-3"/>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        </div>
                        <div className="p-3"/>
        </div>
            

            
        </div>

        <div className="d-md-none">
            <div className="card border-0">
                <div className="row g-0 ">
                    <div className="col-md-6">
                        <img src={clases} className="img-fluid rounded-start" alt="..."/>
                    </div>
                    <div className="col-md-5">
                        <div className="card-body position-relative">
                            {/*}Textos{*/}
                            <h1 className="card-title text-center">{t("titulacion.magister_na.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <div className="p-3"/>
                            <p className="text-start fs-6">{t("titulacion.magister_na.t2")}</p>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.magister_na.t3")}</p>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.magister_na.t5")} <a className="text-decoration-none color-uai" href={folleto} target="_blank" rel="noopener noreferrer" download="Folleto_Master_Forum.pdf">{t("titulacion.magister_na.t6")}</a> {t("titulacion.magister_na.t7")}</p>
                            <div className="p-3"/>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                    
                            <div className="bg-grey">
                                <div className="position-relative">     
                                    <div className="p-3"/>
                                    <h1 className="text-center fs-3">{t("titulacion.magister_na.t8")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                    <div className="p-3"/>
                                    <ul>
                                        <li className="fs-6"><a className="text-decoration-none color-uai" href="http://ingenieria.uai.cl/postgrados/magister-en-ciencias-de-la-ingenieria/">{t("titulacion.magister_na.t9")}</a> {t("titulacion.magister_na.t10")}
                                        <div className="p-2"/>
                                            <ul>
                                                <li className="fs-6">{t("titulacion.magister_na.LineDirector1")} (<a className="text-decoration-none color-uai" href="mailto:tledger@uai.cl">{t("titulacion.magister_na.LineDirector1_mail")}</a>)</li>
                                                <li className="fs-6">{t("titulacion.magister_na.LineDirector2")} (<a className="text-decoration-none color-uai" href="mailto:dafne.crutchik@uai.cl">{t("titulacion.magister_na.LineDirector2_mail")}</a>)</li>
                                                <li className="fs-6">{t("titulacion.magister_na.LineDirector3")} (<a className="text-decoration-none color-uai" href="mailto:ruth.murrugarra@uai.cl">{t("titulacion.magister_na.LineDirector3_mail")}</a>)</li>
                                                <li className="fs-6">{t("titulacion.magister_na.LineDirector4")} (<a className="text-decoration-none color-uai" href="mailto:danilo.borquez@uai.cl">{t("titulacion.magister_na.LineDirector4_mail")}</a>)</li>
                                                <li className="fs-6">{t("titulacion.magister_na.LineDirector5")} (<a className="text-decoration-none color-uai" href="mailto:federico.antico@uai.cl">{t("titulacion.magister_na.LineDirector5_mail")}</a>)</li>
                                                <li className="fs-6">{t("titulacion.magister_na.LineDirector6")} (<a className="text-decoration-none color-uai" href="mailto:felipe.larrain@uai.cl">{t("titulacion.magister_na.LineDirector6_mail")}</a>)</li>
                                                <li className="fs-6">{t("titulacion.magister_na.LineDirector7")} (<a className="text-decoration-none color-uai" href="mailto:pedro.reszka@uai.cl">{t("titulacion.magister_na.LineDirector7_mail")}</a>)</li>
                                            </ul>
                                            <div className="p-2"/>
                                        </li> 
                                        <li className="fs-6"><a className="text-decoration-none color-uai" href="http://ingenieria.uai.cl/postgrados/magister-en-ingenieria-industrial-e-investigacion-de-operaciones/">{t("titulacion.magister_na.t21")}</a>{t("titulacion.magister_na.t22")}{t("titulacion.magister_na.t23")}{t("titulacion.magister_na.t24")}<a className="text-decoration-none color-uai" href="mailto:wilfredo.yushimito@uai.cl">{t("titulacion.magister_na.t25")}</a>)</li>
                                        <li className="fs-6"><a className="text-decoration-none color-uai" href="https://ingenieria.uai.cl/master-of-science/master-of-science-in-data-science/">{t("titulacion.magister_na.t26")}</a>{t("titulacion.magister_na.t27")}{t("titulacion.magister_na.t28")}{t("titulacion.magister_na.t29")}<a className="text-decoration-none color-uai" href="mailto:leopoldo.bertossi@uai.cl">{t("titulacion.magister_na.t30")}</a>)</li>
                                    </ul>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                    <div className="p-3"/>
                                </div>
                            </div>
                            <div className="p-3"/> 
                            <h1 className="text-center fs-3">{t("titulacion.magister_na.t31")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <ul>
                                <li className="fs-6">{t("titulacion.magister_na.requisito1")}</li> 
                                <li className="fs-6">{t("titulacion.magister_na.requisito2")}</li>
                                <li className="fs-6">{t("titulacion.magister_na.requisito3")}</li>
                                <li className="fs-6">{t("titulacion.magister_na.requisito4")}</li>
                                <li className="fs-6">{t("titulacion.magister_na.requisito5")}</li>
                            </ul>
                            <div className="p-2"/>
                            <p className="text-start fs-6">{t("titulacion.magister_na.t36")}</p>
                            <div className="p-3"/>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <div className="p-3"/>

                            <div className="p-3"/>
                            <div className="bg-grey">
                                <div className="position-relative">     
                                    <div className="p-3"/>
                                    <h1 className="text-center fs-3">{t("titulacion.magister_na.t37")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                    <div className="p-3"/>
                                    <ul>
                                        <li className="fs-6">{t("titulacion.magister_na.t38")}</li> 
                                        <li className="fs-6">{t("titulacion.magister_na.t39")}</li>
                                                        </ul>
                                    <div className="p-2"/>
                                        <p className="text-start fs-6">{t("titulacion.magister_na.descripcioncarta1")}<a className="text-decoration-none color-uai" href="mailto:postgrados.fic@uai.cl">{t("titulacion.magister_na.correocarta")}</a>{t("titulacion.magister_na.descripcioncarta2")}</p>
                                    <ul>
                                        <li className="fs-6"><a className="text-decoration-none color-uai" href={mci} target="_blank" rel="noopener noreferrer" download="Carta_MCI.docx">{t("titulacion.magister_na.infocarta1")}</a></li>
                                        <li className="fs-6"><a className="text-decoration-none color-uai" href={miio} target="_blank" rel="noopener noreferrer" download="Carta_MIIO.doc">{t("titulacion.magister_na.infocarta2")}</a></li>
                                        <li className="fs-6"><a className="text-decoration-none color-uai" href={msds} target="_blank" rel="noopener noreferrer" download="Carta_MSDS.doc">{t("titulacion.magister_na.infocarta3")}</a></li>
                                        <li className="fs-6">{t("titulacion.magister_na.t44")}</li>
                                    </ul>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                    <div className="p-3"/>
                                </div>
                            </div>
                            <div className="col-md-6 offset-md-3">  
                        <div className="position-relative">     
                            <div className="p-3"/>

                            <h1 className="text-center fs-3">{t("titulacion.magister_na.t45")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <p className="text-start fs-6">{t("titulacion.magister_na.infopostulacion1")}</p>
                            <p className="text-start fs-6">{t("titulacion.magister_na.infopostulacion2")}</p>
                            <p className="text-start fs-6">{t("titulacion.magister_na.infopostulacion3")} <a className="postula-aqui" href="
https://forms.office.com/r/WKJh42RZqR">{t("titulacion.magister_na.infopostulacion4")}</a></p>
                            <div className="p-3"/>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        </div>
                        <div className="p-3"/>
        </div>
                                        
                            </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    )
}



export default MagisterTitulacion;



